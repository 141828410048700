.App {
  text-align: center;
}
body{
  background-color: black;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
    background-color: rgb(10,10,10);
}

.App-link {
  color: #61dafb;
}
.Img-width{
  width:100%;
  max-width: 300px;
}
.Div-width{
  -webkit-box-shadow: -4px 10px 28px -15px rgba(0,0,0,0.75);
-moz-box-shadow: -4px 10px 28px -15px rgba(0,0,0,0.75);
box-shadow: -4px 10px 28px -15px rgba(0,0,0,0.75);
  max-width: 400px;
  background-color: white;


}
.Div-border{
  border: 1px solid blue;
}
.Div-title{
  font-size: 20px;
  font-weight: bold;
  background-color: black;
  color:white;
  padding:20px;

}
.Div-body{
  font-size:16px;

}
.Div-price{
  font-size:30px;
  font-weight: bold;
  color:rgb(0,128,0)
}

.Div-stock{
  font-size: 14px;
  margin-left:10px;
  margin-right:10px;
  display:inline-block;

}
.Div-spec{
  font-size: 14px;

  margin-right:10px;
  display:inline-block;
  color: blue;
}
.Div-spec a,a:hover,a:link, a:visited{
  text-decoration: none;
  text-transform: uppercase;
  color: blue;
}

.Div-space{
  margin-bottom: 30px;
}

.myButton {
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
	background-color:#ffffff;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#666666;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
    margin-bottom:100px;
}
.myButton:hover {
	background:linear-gradient(to bottom, #f6f6f6 5%, #ffffff 100%);
	background-color:#f6f6f6;
  	color:#666666;
}
.myButton:active {
  	color:#666666;
	position:relative;
	top:1px;
}

.myButton:link, visited {
  	color:#666666;
	color:#666666;
}
.Video-player{

  position: fixed;
  left: 50%;
transform: translateX(-50%);
  max-width: 400px;
}
.Vid-frame{
  width:100%;
  max-width: 400px;
  height:400px;
}

.Vid-space{
  height:400px;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 50%;
transform: translateX(-50%);
  width: 400px;
  height: 100%;
  background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(10,10,10,0) 0%, rgba(10,10,10,1) 20%, rgba(0,0,0,1) 100%);
  z-index: 999;
  cursor: pointer;
  border:1px solid rgb(50,0,0);

}

.OverlayImg {
  position: fixed;
  top: 0px;

  width: 400px;
  height: 100%;
   background-image: url("./graphic2.png");
   background-repeat: no-repeat;
}
.Click-view{
  position: relative;
  top:150px;
  font-size: 40px;
  color:white;
  padding-top:100px;
  opacity: .7;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
